import React, { useEffect, useState, useRef, useContext } from "react";
import { useParams } from "react-router-dom";
import { Basicprofile } from "./Basicprofile.js";
import { Nvbarprofile } from "./navbarprofile.js";
import { ProfileModal } from "./ProfileModal.js";
import { useNavigate } from "react-router-dom";

import { Textosprofile } from "./Textosprofile.js";
import { Academic } from "./Academico.js";
import { Personal } from "./Personal.js";
import { Onlinepresencial } from "./onlinepresencial";

import { NavBarSup } from "../navbar/navbarsup.js";
import Fotr_log from "../Footer/footerlog";

// import "../workspace/Work.css";
import "./profile.css";
import axios from "axios";
import { Preferencias } from "./Preferencias.js";
import { Keyfactors } from "./Keyofme.js";

import { UserContext } from "../login/login-context";

// import "react-calendar/dist/Calendar.css";
function Pproftest() {
  const { user_id } = useParams();
  const [perfildata, setPerfildata] = useState({});

  // eslint-disable-next-line no-unused-vars
  const [loading, setLoading] = useState(true); // Estado de carga
  const [lgbt, setLgbt] = useState("");
  const [feminism, setFeminism] = useState("");
  const [spiritual, setSpiritual] = useState("");
  const [religion, setReligion] = useState("");
  const [native, setNative] = useState("");
  const [neurodivergent, setNeurodivergent] = useState("");
  const [items, setItems] = useState("");
  const [keyitems, setKeyitems] = useState("");

  console.log(keyitems + "desde funcion raiz");
  const section1Ref = useRef(null);
  const section2Ref = useRef(null);

  const scrollToSection = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  };

  // eslint-disable-next-line no-unused-vars
  const [user, setUser] = useContext(UserContext);
  const [loggedIn, setLoggedIn] = useState(false);

  useEffect(() => {
    const profile = window.location.href.split("/").at(-1);
    if (user && user.logged_in && user.profile_link == profile) {
      setLoggedIn(true);
    } else {
      setLoggedIn(false);
    }
  }, [user]);
console.log(user_id+"sesioniniciada")
  const navigate = useNavigate();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`/api/psfeeder/${user_id}`);
        setPerfildata(response.data);
        setLgbt(response.data["lgbt"]);
        setFeminism(response.data["feminism"]);
        setReligion(response.data["religion"]);
        setSpiritual(response.data["spiritual"]);
        setNative(response.data["native"]);
        setItems(response.data["items"]);
        setKeyitems(response.data["keyitems"]);
        setNeurodivergent(response.data["neurodivergent"]);
        console.log("Respuesta selection:", response.data);
      } catch (error) {
        console.error("Error al cargar los datos:", error);
        navigate("/");
      } finally {
        setLoading(false); // Finaliza la carga
      }
    };
    fetchData();
    console.log(perfildata + " este es el print ");
  }, []);

  const handlePestanaChange = (newPestana) => {
    setSelectedPestana(newPestana);
  };

  const [selectedPestana, setSelectedPestana] = useState(
    // loggedIn ? 'personal' : ''
    "personal",
  );

  return (
    <div
      style={{
        width: "100%",
        background: "#f7f7f7",
        height: "100%",
      }}
    >
      <ProfileModal
        first_time_login={perfildata["first_time_login"]}
        session={loggedIn}
      />
      <div className="row no-gutters perfilcontenedor">
        <NavBarSup  profile="true"/>
        <div className="col-lg-3">
          <Basicprofile
            user={user}
            titulo={perfildata["title"]}
            nombre={perfildata["name"]}
            apellido={perfildata["last_name"]}
            genero={perfildata["gender"]}
            exp={perfildata["years_of_experience"]}
            enfoque={perfildata["approach"]}
            minsal={perfildata["minsal_reg_no"]}
            nacionalidad={perfildata["nationality"]}
            img={perfildata["profile_picture"]}
            session={loggedIn}
          />
        </div>

        <div className="col-lg-9 perfilcentral" ref={section2Ref}>
          <div
            style={{
              position: "static",
              margin: "0px",
              zIndex: "9",
              padding: "0%",
              width: "100%",
            }}
          >
            <Nvbarprofile
              onPestanaChange={handlePestanaChange}
              section1Ref={section1Ref}
              scrollToSection={scrollToSection}
              session={loggedIn}
              section2Ref={section2Ref}
            />
          </div>

          <div style={{ background: "white" }} ref={section1Ref}>
            <div
              className="boxprofile"
              style={{
                marginLeft: "0.5%",
                marginRight: "0.5%",
                padding: "2%",
                display: selectedPestana === "personal" ? "block" : "none",
              }}
            >
              <Keyfactors item={keyitems} session={loggedIn} />

              <Preferencias pref={items} session={loggedIn} />
            </div>

            <div
              className="mt-1 boxprofile"
              style={{
                display: selectedPestana === "descripcion" ? "block" : "none",
              }}
            >
              <Textosprofile
                sobremi={perfildata["about_me"] || ""}
                propuesta={perfildata["proposal"] || ""}
                especialidad={perfildata["specialty"] || ""}
                session={loggedIn}
              />
            </div>

            <div
              className="mt-1 boxprofile"
              style={{
                display: selectedPestana === "estudios" ? "block" : "none",
              }}
            >
              <Academic session={loggedIn} posgrade={perfildata["postgrade"]} />
            </div>
            <div
              className="mt-1 boxprofile"
              style={{
                display: selectedPestana === "comentarios" ? "block" : "none",
              }}
            >
              <p>
                {" "}
                Actualmente no tenemos comentarios para{" "}
                {perfildata["name"] + " " + perfildata["last_name"]}{" "}
              </p>
            </div>

            <div
              className="mt-1 boxprofile"
              style={{
                display:
                  selectedPestana === "personal" && loggedIn ? "block" : "none",
              }}
            >
              <Personal
                session={loggedIn}
                lgbt={lgbt}
                feminism={feminism}
                spiritual={spiritual}
                neurodivergent={neurodivergent}
                native={native}
                religion={religion}
              />
            </div>

            <article
              className="costos mt-1 boxprofile"
              style={{ marginLeft: "0.5%", marginRight: "0.5%", padding: "2%" }}
            >
              <Onlinepresencial
                Nombre={perfildata["name"]}
                apellido={perfildata["last_name"]}
                session={loggedIn}
              />
            </article>
          </div>
        </div>

        {/* <div className="col-lg-3 terceracol">
          <div className="card">
          
            <div className="card-body"></div>
          </div>
        </div> */}
      </div>
      {/* <div style={{position:"fixed", bottom:"0%", margin:"0px", zIndex:"9", padding:"0%", width:"fit-content"}}>
      <Nvbarprofile  onPestanaChange={handlePestanaChange} section1Ref={section1Ref} scrollToSection={scrollToSection} section2Ref={section2Ref}/>
      </div> */}
      <Fotr_log />
    </div>
  );
}

export default Pproftest;
