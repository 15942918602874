import React, { useState } from "react";


// eslint-disable-next-line react/prop-types
export function Nvbarprofile({ onPestanaChange, section1Ref , scrollToSection, section2Ref ,session}) {

  // eslint-disable-next-line no-unused-vars
  const [pestana, setPestana] = useState('personal');




  const selectedPestana = (name) => {
    setPestana(name);
    // Llamar a la función del componente padre para actualizar el estado
    onPestanaChange(name);
  };
  



  return (
    <div className="card-text">
      <ul className="list-unstyled navlist" >
     
        <li  className={`py-1 espprof2 navprofile ${pestana==="personal" ? "selectedpestana" : ""}`} onClick={(e) => { e.preventDefault(); scrollToSection(section2Ref); selectedPestana('personal')}}>
        <i className="fa-solid fa-user"></i>
          <p className="textnavprofile" > Resumen </p>
        </li>
        <li  className={`py-1 espprof2 navprofile ${pestana==="descripcion" ? "selectedpestana" : ""}`} onClick={(e) => { e.preventDefault(); scrollToSection(section2Ref); selectedPestana('descripcion')}}>

          <i className="fa-solid fa-address-card"></i>
          <p className="textnavprofile">Descripción </p>
        </li>
        <li  className={`py-1 espprof2 navprofile ${pestana==="" ? "selectedpestana" : ""}`}onClick={(e) => { e.preventDefault(); scrollToSection(section1Ref); selectedPestana('')}} >
       <i className="fa-solid fa-calendar" ></i>

          <p className="textnavprofile"> {session ? "Disponibilidad" : "Agendar"} </p>
        </li>
        <li  className={`py-1 espprof2 navprofile ${pestana==="estudios" ? "selectedpestana" : ""}`} onClick={(e) => { e.preventDefault(); scrollToSection(section2Ref); selectedPestana('estudios')}}>
          <i className="fa-solid fa-graduation-cap"></i>
          <p className="textnavprofile">Estudios </p>
        </li>

        <li  className={`py-1 espprof2 navprofile ${pestana==="comentarios" ? "selectedpestana" : ""}`}onClick={(e) => { e.preventDefault(); scrollToSection(section2Ref); selectedPestana('comentarios')}}>
          <i className="fa-solid fa-comments"></i>
          <p className="textnavprofile" >Comentarios </p>
        </li>
        {/* <li className={`py-1 espprof2 navprofile ${session ? "show" : "notshow"}`} onClick={logOut}>
        <i className="fa-solid fa-right-to-bracket"></i>
          <p className="textnavprofile"  >Log Out</p>
        </li>
        */}

       
      </ul>
    </div>
  );
}


