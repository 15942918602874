import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import axios from "axios";
// import "../workspace/Work.css";
import "./profile.css";
import Cookies from "js-cookie";

export function Textosprofile({
  sobremi: initiaSobre,
  propuesta: initiaProp,
  especialidad: initiaEsp,
  session,
}) {
  Textosprofile.propTypes = {
    sobremi: PropTypes.string,
    propuesta: PropTypes.string,
    especialidad: PropTypes.string,
    session: PropTypes.bool,
  };
  // const [isExpanded, setisExpanded] = useState(false);
  // const expandir = () => {
  //   setisExpanded(!isExpanded);
  // };

  const [editarsobremi, setEditarsobremi] = useState(false);
  const [editarprop, setEditarprop] = useState(false);
  const [editaresp, setEditaresp] = useState(false);

  const update_info = (info, setText, text) => {
    axios
      .post("/api/update_user_info", info, {
        withCredentials: true,
        headers: { "X-CSRF-TOKEN": Cookies.get("csrf_access_token") },
      })
      .then((response) => {
        console.log(response);
        setText(text);
      })
      .catch((error) => console.log(error));
  };

  const eSobremi = () => {
    setEditarsobremi(false);
    update_info({ about_me: textsobre }, setTextsobre, textsobre);
  };
  const eProp = () => {
    setEditarprop(false);
    update_info({ therapeutic_proposal: textprop }, setTextprop, textprop);
  };
  const eEsp = () => {
    setEditaresp(false);
    update_info({ specialty: textesp }, setTextesp, textesp);
  };

  const [textsobre, setTextsobre] = useState(
    initiaSobre
      ? initiaSobre
      : "Tus futuros pacientes quisieran saber algo sobre ti",
  );
  const [textprop, setTextprop] = useState(
    initiaProp
      ? initiaProp
      : "También quisieran saber algo sobre tu propuesta terapéutica",
  );
  const [textesp, setTextesp] = useState(
    initiaEsp ? initiaEsp : "Y como te has especializado",
  );

  useEffect(() => {
    setTextsobre(initiaSobre ? initiaSobre : "");
  }, [initiaSobre]);

  useEffect(() => {
    setTextprop(initiaProp ? initiaProp : "");
  }, [initiaProp]);

  useEffect(() => {
    setTextesp(initiaEsp ? initiaEsp : "");
  }, [initiaEsp]);

  // const [isExpanded_vis, setisExpanded_vis] = useState(false);
  // const expandir_vis = () => {
  //   setisExpanded_vis(!isExpanded_vis);
  // };

  // const [isExpanded_mi, setisExpanded_mi] = useState(false);
  // const expandir_mi = () => {
  //   setisExpanded_mi(!isExpanded_mi);
  // };
  return (
    <>
      <article className="desc-personal">
        {/* Sobre mi */}

        <h5 className="card-title">
          Sobre mí
          <i
            className={`fa-solid fa-pen ${session ? "show" : "notshow"}`}
            style={{ fontSize: "13px" }}
            onClick={() => {
              setEditarsobremi(true);
            }}
          ></i>
        </h5>

        <p
          // className={`card-text sobremi ${editarsobremi ? "notshow" : ""} ${isExpanded_mi ? "expanded" : ""} ${textsobre.length > 120 ? "clarity" : ""}`}
          className={`card-text sobremi ${editarsobremi ? "notshow" : ""}`}

          style={{ textAlign: "left", whiteSpace: "pre-line" }}
        >
          {textsobre.length >= 1
            ? textsobre
            : session
              ? "Tus futuros pacientes quieren saber algo sobre ti"
              : "Lo sentimos aún no tenemos información"}
        </p>
        <textarea
          value={textsobre}
          maxLength="550"
          onChange={(e) => setTextsobre(e.target.value)}
          className={`caja-texto-profile ${editarsobremi ? "show" : "notshow"}`}
        ></textarea>

        <div style={{ textAlign: "right" }}>
          <button
            className={`button-login profilebutton ${editarsobremi ? "show" : "notshow"}`}
            onClick={eSobremi}
          >
            Guardar
          </button>
        </div>

        {/* <a className="expandir" onClick={expandir_mi}>
          {" "}
          {`${isExpanded_mi || textsobre.length < 120 ? "" : "..."}`}
          <i
            className={`fa-solid fa-caret-${isExpanded_mi ? "up" : "down"} ${textsobre.length < 120 ? " notshow" : " show"} `}
            style={{ margin: "2%", fontSize: "22px" }}
          ></i>
        </a> */}
      </article>

      {/* propuesta */}
      <article className="desc-personal">
        <h5 className="card-title">
          Mi propuesta terapéutica
          <i
            className={`fa-solid fa-pen ${session ? "show" : "notshow"}`}
            style={{ fontSize: "13px" }}
            onClick={() => {
              setEditarprop(true);
            }}
          ></i>
        </h5>

        <p
          // className={`card-text vision ${editarprop ? "notshow" : ""} ${isExpanded_vis ? "expanded" : ""} ${textprop.length > 120 ? "clarity" : ""}`}
          className={`card-text vision ${editarprop ? "notshow" : ""}`}

          style={{ textAlign: "left", whiteSpace: "pre-line" }}
        >
          {textprop.length >= 1
            ? textprop
            : session
              ? "También quisieran saber de tu propuesta terapeutica"
              : "Lo sentimos aún no tenemos información"}
        </p>
        <textarea
          value={textprop}
          maxLength="550"
          onChange={(e) => setTextprop(e.target.value)}
          className={`caja-texto-profile ${editarprop ? "show" : "notshow"}`}
        ></textarea>
        <div style={{ textAlign: "right" }}>
          <button
            className={`button-login profilebutton ${editarprop ? "show" : "notshow"}`}
            onClick={eProp}
          >
            Guardar
          </button>
        </div>

        {/* <a className="expandir" onClick={expandir_vis}>
          {" "}
          {`${isExpanded_vis || textprop.length < 120 ? "" : "..."}`}
          <i
            className={`fa-solid fa-caret-${isExpanded_vis ? "up" : "down"}  ${textprop.length < 120 ? " notshow" : " show"} `}
            style={{ margin: "2%", fontSize: "22px" }}
          ></i>
        </a> */}
      </article>

      {/* Recursos Especialidad */}
      <article className="desc-personal">
        <h5 className="card-title">
          Especialidad
          <i
            className={`fa-solid fa-pen ${session ? "show" : "notshow"}`}
            style={{ fontSize: "13px" }}
            onClick={() => {
              setEditaresp(true);
            }}
          ></i>
        </h5>
        <p
          // className={`card-text especial ${editaresp ? "notshow" : ""} ${isExpanded ? "expanded" : ""}  ${textesp.length > 120 ? "clarity" : ""}`}
          className={`card-text especial ${editaresp ? "notshow" : ""}`}

          style={{ textAlign: "left", whiteSpace: "pre-line" }}
        >
          {textesp.length >= 1
            ? textesp
            : session
              ? "Y cómo te has especializado"
              : "Lo sentimos aún no tenemos información"}
        </p>

        <textarea
          value={textesp}
          maxLength="550"
          onChange={(e) => setTextesp(e.target.value)}
          className={`caja-texto-profile ${editaresp ? "show" : "notshow"}`}
        ></textarea>

        <div style={{ textAlign: "right" }}>
          <button
            className={`button-login profilebutton ${editaresp ? "show" : "notshow"}`}
            onClick={eEsp}
          >
            Guardar
          </button>
        </div>
{/* 
        <a className={`expandir`} onClick={expandir}>
          {" "}
          {`${isExpanded || textesp.length < 120 ? "" : "...."}`}
          <i
            className={`fa-solid fa-caret-${isExpanded ? "up" : "down"} ${textesp.length < 100 ? " notshow" : " show"} `}
            style={{ margin: "2%  x", fontSize: "22px" }}
          ></i>
        </a> */}
      </article>
    </>
  );
}
