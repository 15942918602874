import React, { useState } from "react";
import PropTypes from "prop-types";
import "./week.css";
function Weekly({ isOnline }) {
  // eslint-disable-next-line react/prop-types
  // function Weekly({ isOnline, onlinehrs, presencialhrs }) {
  // Datos de ejemplo para las horas disponibles

  const initialHours = {
    Lun: ["09:00", "10:00", "11:00", "18:00"],
    Mar: ["10:00", "13:00"],
    Mier: ["09:00", "10:00", "14:00"],
    Jue: ["15:00"],
    Vie: ["11:00", "14:00"],
  };
  // const initialHours = onlinehrs;
  // const initialHours2 = presencialhrs;

  const initialHours2 = {
    Lun: [""],
    Mar: [""],
    Mier: [""],
    Jue: [""],
    Vie: [""],
  };
  // Estado para almacenar las horas disponibles y la hora seleccionada para cada día
  const [hours] = useState(initialHours);
  const [selectedHours, setSelectedHours] = useState({});
  const [hours2] = useState(initialHours2);

  // Función para manejar la selección de hora
  const handleHourSelect = (day, hour) => {
    const updatedSelectedHours = { ...selectedHours };

    // Deseleccionar la hora previamente seleccionada para este día
    for (const selectedDay in updatedSelectedHours) {
      if (selectedDay !== day) {
        delete updatedSelectedHours[selectedDay];
      }
    }

    // Seleccionar la nueva hora para este día
    updatedSelectedHours[day] = hour;
    setSelectedHours(updatedSelectedHours);
  };

  return (
    <div
      className="container_week"
      style={{ maxWidth: "fit-content", padding: "0%" }}
    >
      <h5
        className="lfhw"
        style={{
          height: "20px",
          marginTop: "5%",
          marginBottom: "5%",
          fontSize: "20px",
          fontWeight: "450",
          textAlign: "center",
          color: "#17354d",
          fontFamily: "Roboto",
        }}
      >
        Horario disponible
      </h5>
      <div className="d-flex justify-content-start" style={{ width: "98.4%" }}>
        <span>
          <i
            className="fa-solid fa-angles-left"
            style={{ color: "#ffb013" }}
          ></i>
        </span>
        {isOnline
          ? Object.keys(hours).map((day) => (
              <div key={day} className="card mx-2 dayweek">
                <div className="card-body dayweekbd">
                  <h5 className="card-title wktitl">{day}</h5>
                  <ul className="list-unstyled">
                    {hours[day].length > 0 ? (
                      hours[day].map((hour, index) => (
                        <li
                          key={index}
                          className={`rounded-pill px-3 py-1 my-1 ${selectedHours[day] === hour ? "selected-hour" : "default-hour"}`}
                          onClick={() => handleHourSelect(day, hour)}
                        >
                          {hour}
                        </li>
                      ))
                    ) : (
                      <li></li>
                    )}
                  </ul>
                </div>
              </div>
            ))
          : Object.keys(hours2).map((day) => (
              <div key={day} className="card mx-2 dayweek">
                <div className="card-body dayweekbd">
                  <h5 className="card-title wktitl">{day}</h5>
                  <ul className="list-unstyled">
                    {hours2[day].length > 0 ? (
                      hours2[day].map((hour, index) => (
                        <li
                          key={index}
                          className={`rounded-pill px-3 py-1 my-1 ${selectedHours[day] === hour ? "selected-hour" : "default-hour"}`}
                          onClick={() => handleHourSelect(day, hour)}
                        >
                          {hour}
                        </li>
                      ))
                    ) : (
                      <li></li>
                    )}
                  </ul>
                </div>
              </div>
            ))}
        <span>
          <i
            className="fa-solid fa-angles-right"
            style={{ color: "#ffb013" }}
          ></i>
        </span>
      </div>
    </div>
  );
}
Weekly.propTypes = {
  isOnline: PropTypes.bool.isRequired,
};

export default Weekly;
